export const baselineHeight = '1.4';

export enum fontSizes {
    xsmall = '10px',
    msmall = '11px',
    smaller = '12px',
    small = '13px',
    small14 = '14px',
    base = '15px',
    medium = '16px',
    header = '17px',
    large = '18px',
    xlarge = '20px',
    xlarger = '30px',
    iconLarge = '36px',
    xxlarge = '40px',
    FocusElement = '16px',
    pgLarge = '24px',
    pgXlarge = '32px',
    hero = '48px',
    subHero = '34px',
}

export enum lineHeights {
    xsmall = '16px',
    small = '20px',
    base = '22px',
    medium = '26px',
    large = '28px',
    xlarge = '32px',
    xxlarge = '44px',
}

export const fontLevelBox = {
    xsmall: `font-size: ${fontSizes.xsmall}; line-height: ${lineHeights.xsmall};`,
    small: `font-size: ${fontSizes.small}; line-height: ${lineHeights.small};`,
    base: `font-size: ${fontSizes.base}; line-height: ${lineHeights.base};`,
    medium: `font-size: ${fontSizes.header}; line-height: ${lineHeights.medium};`,
    large: `font-size: ${fontSizes.xlarge}; line-height: ${lineHeights.large};`,
    xlarge: `font-size: ${fontSizes.pgLarge}; line-height: ${lineHeights.xlarge};`,
    xxlarge: `font-size: ${fontSizes.xlarger}; line-height: ${lineHeights.xxlarge};`,
};

export const fontLevelHeadingBox = {
    'h1-hero': `font-size: ${fontSizes.hero}; line-height: 64px;`,
    'h1-sub': `font-size: ${fontSizes.subHero}; line-height: 56px;`,
    h1: `font-size: ${fontSizes.xlarger}; line-height: ${lineHeights.xxlarge};`,
    h2: `font-size: ${fontSizes.pgLarge}; line-height: ${lineHeights.xlarge};`,
    h3: `font-size: ${fontSizes.xlarge}; line-height: ${lineHeights.large};`,
    h4: `font-size: ${fontSizes.header}; line-height: ${lineHeights.medium};`,
};

// todo new font-sizes to be updated in 2nd part of font update

// export enum fontSizes {
//     xsmall = '11px',
//     smaller = '12px',
//     small = '13px',
//     base = '15px',
//     medium = '17px',
//     large = '20px',
//     xlarge = '24px',
//     xlarger = '30px',
//     iconLarge = '36px',
//     xxlarge = '30px',
//     FocusElement = '16px',
//     pgLarge = '24px',
//     pgXlarge = '32px',
//     hero = '48px',
//     display = '34px',
//     focusElement = '15px',
//     focusElementLarge = '17px',
// }

export enum boxSizes {
    boxHeight = '44px',
    boxHeightBase = 44,
    borderWidth = '1px',
    borderWidth2 = '2px',
    borderRadius = '2px',
    borderRadius3 = '3px',
    borderRadius4 = '4px',
    borderRadius5 = '5px',
    borderRadius8 = '8px',
    borderRadius12 = '12px',
}

export enum fontWeights {
    light = '300',
    normal = '400',
    lightBold = '500',
    bold = '600',
    bolder = '700',
    heavy = '800',
}

export enum breakpoints {
    xsmall = '320px',
    msmall = '375px',
    small = '480px',
    medium = '768px',
    large = '1024px',
    xlarge = '1280px',
    xxlarge = '1349px',
}

/** Widths of the iframe, used as breakpoints */
export enum carFinanceCalculatorPartnershipWidth {
    /** Usually `320px` */
    xsmall = '320px',
    /** Usually `480px` */
    small = '400px',
    /** Usually `768px` */
    medium = '500px',
    /** Usually `1024px` */
    large = '656px',
    /** Usually `1280px` */
    xlarge = '800px',
    /** Usually `1349px` */
    xxlarge = '1000px',
}

export enum gutterSizes {
    xxsmall = '1px',
    xsmall = '2px',
    small = '4px',
    base = '8px',
    medium10 = '10px',
    medium = '12px',
    large = '16px',
    large18 = '18px',
    xlarge = '24px',
    xxlarge = '32px',
    xxxlarge = '44px',
    xxxxlarge = '48px',
}

export enum colors {
    darkGrey = '#635b67',
    bark80 = '#635b67',
    bark60 = '#8a848d',
    bark40 = '#b1adb3',
    bark20 = '#d8d6d9',
    textDarkGrey = '#5c5c5c',
    branch60 = '#938f93',
    whatsapp = '#25d366',
    branch20 = '#dbdadb',
    branch10 = '#f1f1f1',
    branch5 = '#f6f5f6',
    beige = '#f5f5f5',
    blade70 = '#557e8d',
    mapleDark = '#b83f0e',
    blade10 = '#e6ecee',
    maple = '#f16f3c',
    blue = '#007FB0',
    myrtleDark = '#787673',
    blue40 = '#99CCDF',
    myrtle = '#f0ece6',
    blue10 = '#E6F2F7',
    blue60 = '#007FB0',
    branchDark = '#262226',
    red = '#e52815',
    blade = '#0d475c',
    red10 = '#fdecea',
    berryDark = '#B54343',
    yellow = '#efc636',
    berry = '#e75462',
    orange = '#ff9900',
    leafDark = '#39781b',
    green = '#52a744',
    leaf = '#72ef36',
    leafHover = '#68D634',
    green10 = '#eef8ed',
    barkDark = '#1e1921',
    white = '#fff',
    bark = '#3c3241',
    lightgrey = '#fafafa',
    messenger = '#0084ff',
    black = '#000000',
    lighterGrey = '#e2e2e2',
    facebook = '#37589a',
    lightGrey = '#d5d5d5',
    twitter = '#38a8e0',
    grey = '#bbbbbb',
    pinterest = '#c91618',
    bark10 = '#f1f0f1',
    branch40 = '#b7b4b7',
    grey10 = '#DBDADB',
    gtallGreen = '#498124',
    gtallBlue = '#458FFF',
    surface = '#F6F6F7',
    surfaceHover = '#ECEDEF',
    darkGreen = '#527435',
    navy = '#0d495c',
    newBrand = '#5CF100',
    newBrand40 = '#80F14A66',
    newBrand30 = '#80F14A30',
    newBrandHoverState = '#54DB00',
    messageTextColor = '#1F2F3B',
    newBorder = '#DFE1E2',

    fgInfo = '#1B73E8',
    fgDanger = '#D03E3E',
    fgSuccess = '#028546',
    fgButtonDisabled = '#636E77',
    bgButtonDisabled = surfaceHover,
    bgInfo = '#E8F1FD',
    fgPrimary = '#0C4167',
    newGrey = '#8E969C',
    newDarkGrey = '#495660',
    newLigthGrey = '#B6BBBF',
    newBrandYellow = '#FDCE0C',
    newBrandYellowDarker = '#FDCE0C',
    paginationBorder = '#babfc3',
    paginationDisabled = '#fafbfb',
    paginationLightGrey = '#bdc1cc',
    vrmBgYellow = '#fdec33',
    newDanger = '#FAECEC',

    // Transparency
    shadowLight = '#00000033',
}

export enum colorVariables {
    mainPrimary = colors.berry,
    mainPrimaryDark = colors.berryDark,
    mainSecondary = colors.bark,
    mainSecondaryDark = colors.barkDark,
    mainTertiary = colors.blue,
    mainQuaternary = colors.leafDark,

    // Background
    backgroundMedium = colors.branch20,
    backgroundLighter = colors.branch5,
    backgroundLight = colors.branch10,
    backgroundNeutral = colors.white,
    backgroundDark = colors.branch40,
    backgroundDarker = colors.branch60,
    backgroundPassive = colors.yellow,
    backgroundStriped = colors.blade10,
    backgroundHighlighted = colors.green,

    // Text
    textPrimary = colors.bark,
    textSecondary = colors.bark60,
    textNeutral = colors.bark80,
    textLight = colors.bark40,
    textHighlighted = colors.green,
    textLink = colors.blue,
    textAppBanner = colors.blade70,
    textPrice = colors.green,
    textHighlightedDescription = colors.blade,

    // Borders
    borderLighter = colors.bark10,
    borderLight = colors.bark20,
    borderMedium = colors.bark40,
    borderDark = colors.bark80,
    borderHighlighted = colors.green,

    // Shadows
    shadow = colors.branch20,

    // Transparency
    shadowLight = colors.bark40,

    // Disabled items
    disabledBackground = colors.branch10,
    disabledText = colors.bark40,
    disabledBorder = colors.branch20,

    // Fonts
    fontPrimary = colors.bark,
    fontSecondary = colors.bark80,
    fontTeritary = colors.bark60,
    fontQuaternary = colors.bark40,
    fontLight = colors.white,

    // Third party
    facebook = colors.facebook,
    twitter = colors.twitter,
    pinterest = colors.pinterest,
    whatsapp = colors.whatsapp,
    messenger = colors.messenger,
}

export enum zIndexes {
    low = 100,
    medium = 500,
    high = 1000,
}

export enum boxShadow {
    image = `0px 10px 20px #d5d5d5`,
    spinnerLoader = '-10px 35px 25px 10px #3c3241',
    default = '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
}

const baseFontSize = 16;
export const mediaQuery = {
    pxToEm(breakpoint) {
        return parseInt(breakpoint, 10) / baseFontSize;
    },
    from(breakpoint: Breakpoints) {
        return `@media (min-width: ${this.pxToEm(breakpoint)}em)`;
    },
    until(breakpoint: Breakpoints) {
        return `@media (max-width: ${this.pxToEm(breakpoint) - 0.01}em)`;
    },
    between(from: Breakpoints, until: Breakpoints) {
        return `@media (min-width: ${this.pxToEm(from)}em) and (max-width: ${
            this.pxToEm(until) - 0.01
        }em)`;
    },
    touchDeviceOnly() {
        return '@media (hover: none)';
    },
};

type Breakpoints = breakpoints | carFinanceCalculatorPartnershipWidth;
