interface State {
    title: string;
    path: string;
    items: CategoryDataItem[];
    activeId: null | string;
    isOpen: boolean;
}

export interface CategoryDataItemSubItem {
    name: string;
    path: string;
    seoName: string;
}

export interface CategoryDataItem {
    name: string;
    path: string;
    qaAttribute?: string;
    seoName: string;
    subitems?: CategoryDataItemSubItem[];
    subtitle: string;
}

export const initialState: State = {
    activeId: null,
    items: [],
    isOpen: false,
    path: '',
    title: '',
};

export const UPDATE_CATEGORY_NAV = 'UPDATE_CATEGORY_NAV';
export const CATEGORY_NAV_CLOSE = 'CATEGORY_NAV_CLOSE';
export const CATEGORY_NAV_OPEN = 'CATEGORY_NAV_OPEN';

export function redirectCarCategoryToLandingPage(items: CategoryDataItem[]) {
    return items.map((item) => ({
        ...item,
        ...(item.seoName === 'cars-vans-motorbikes' && {
            name: 'Cars & Vehicles',
            path: '/cars-vans-motorbikes/cars',
        }),
    }));
}

//#region mobile categories
export interface MobileCategoryItem extends CategoryDataItem {
    icon: string;
    gaLabel: string;
}

const mobileSeoNames = {
    'cars-vans-motorbikes': true,
    'for-sale': true,
    'flats-houses': true,
    jobs: true,
    'business-services': true,
};

export function transformMobileCategories(items: CategoryDataItem[]) {
    return items
        .filter(({ seoName }) => mobileSeoNames[seoName])
        .map<MobileCategoryItem>((item) => {
            const seoName = item.seoName as keyof typeof mobileSeoNames;

            switch (seoName) {
                case 'business-services':
                    return { ...item, gaLabel: 'Services', icon: 'icon--spanner' };
                case 'cars-vans-motorbikes':
                    return {
                        ...item,
                        name: 'Cars & Vehicles',
                        path: '/cars-vans-motorbikes/cars',
                        gaLabel: 'Motors',
                        icon: 'icon--car',
                    };
                case 'flats-houses':
                    return {
                        ...item,
                        name: 'Property to Rent',
                        // Preserve location
                        path: item.path.replace('/flats-houses', '/property-to-rent'),
                        gaLabel: 'Property',
                        icon: 'icon--house',
                    };
                case 'for-sale':
                    return { ...item, gaLabel: 'For Sale', icon: 'icon--tag' };
                case 'jobs':
                    return { ...item, gaLabel: 'Jobs', icon: 'icon--tie' };
            }
        });
}
//#endregion

export const updateCategoryNav = (payload) => ({
    type: UPDATE_CATEGORY_NAV,
    payload,
});

export const openCategoryNav = (categoryId, categoryName) => ({
    type: CATEGORY_NAV_OPEN,
    categoryId,
    track: {
        event: 'NavL1CategoryHover',
        eventLabel: categoryName,
    },
});

export const closeCategoryNav = () => ({
    type: CATEGORY_NAV_CLOSE,
});

export default (state = initialState, action): State => {
    switch (action.type) {
        case UPDATE_CATEGORY_NAV:
            return action.payload;
        case CATEGORY_NAV_OPEN:
            return {
                ...state,
                activeId: action.categoryId,
                isOpen: true,
            };
        case CATEGORY_NAV_CLOSE:
            return {
                ...state,
                activeId: null,
                isOpen: false,
            };
        default:
            return state;
    }
};
