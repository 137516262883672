export enum ExperimentVariant {
    VARIANT_A = 'A',
    VARIANT_B = 'B',
    VARIANT_C = 'C',
    VARIANT_D = 'D',
}

export enum ExperimentNames {
    PRICE_GUIDANCE = 'GTALL-17888',
    NEW_VIP = 'GTALL-25626',
    PHONE_AUTH = 'GTALL-27464',
    CORECHAT_READ_BFF = 'GTCC-921-CORECHAT-READ',
    GAM_PARTNERSHIP_ADS = 'GTCO-239',
    REGISTER_LOGIN_BFF = 'GTC-1815',
    LOGIN_MODAL = 'GTC-2229',
    UNREAD_FEATURE_FLAG = 'GTC-2379',
    MANAGE_ADS_BFF = 'GTP-2827',
}

export const GrowthBookFeature = {
    REVIEW_FEATURE_FLAG: 'GTC-2380',
    UNREAD_FEATURE_FLAG: 'GTC-2379',
} as const;
