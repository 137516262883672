// eslint-disable import/no-unresolved
import { testNever } from '@gumtree/shared/src/model/generic.model';
import type {
    ActionFromReducersMapObject,
    CombinedState,
    Reducer,
    StateFromReducersMapObject,
} from 'redux';
import { RoutingPageType, pageNames } from './route-map';

// NOTE: all page types need to be added here in order for webpack
// to specifically generate chunks for them
export default (pageType: RoutingPageType) => {
    switch (pageType) {
        case pageNames.HOME:
            return import(/* webpackChunkName: "home" */ '@gumtree/home');
        case pageNames.SYI:
            return import(/* webpackChunkName: "syi" */ '@gumtree/syi');
        case pageNames.VIP:
            return import(/* webpackChunkName: "vip" */ '@gumtree/vip');
        case pageNames.SELL_MY_CAR:
            return import(/* webpackChunkName: "sell-my-car" */ '@gumtree/sell-my-car');
        case pageNames.USER_PROFILE:
            return import(/* webpackChunkName: "user-profile" */ '@gumtree/user-profile');
        case pageNames.RECRUITER_PROFILE:
            return import(/* webpackChunkName: "recruiter-profile" */ '@gumtree/recruiter-profile');
        case pageNames.JOB_PRICING:
            return import(/* webpackChunkName: "job-pricing" */ '@gumtree/job-pricing');
        case pageNames.NOT_FOUND:
            return import(/* webpackChunkName: "error-404" */ '@gumtree/error-404');
        case pageNames.PRICE_GUIDANCE:
            return import(/* webpackChunkName: "price-guidance" */ '@gumtree/price-guidance');
        case pageNames.MESSAGE_CENTRE:
            return import(/* webpackChunkName: "message-centre" */ '@gumtree/message-centre');
        case pageNames.REPLY_CONFIRMATION:
            return import(
                /* webpackChunkName: "reply-confirmation" */ '@gumtree/reply-confirmation'
            );
        case pageNames.STATIC_PAGES:
            return import(/* webpackChunkName: "static-pages" */ '@gumtree/static-pages');
        case pageNames.SRP:
            return import(/* webpackChunkName: "srp" */ '@gumtree/srp');
        case pageNames.SELLER_ADS:
            return import(/* webpackChunkName: "sellerads" */ '@gumtree/sellerads');
        case pageNames.BUMP_UP:
            return import(/* webpackChunkName: "bump-up" */ '@gumtree/bump-up');
        case pageNames.PROFILE:
            return import(/* webpackChunkName: "profile" */ '@gumtree/profile');
        case pageNames.CARS:
            return import(/* webpackChunkName: "cars" */ '@gumtree/cars');
        case pageNames.REGISTER:
            return import(/* webpackChunkName: "register" */ '@gumtree/register');
        case pageNames.LOGIN:
            return import(/* webpackChunkName: "login" */ '@gumtree/login');
        case pageNames.PROMOTE_AD:
            return import(/* webpackChunkName: "promote-ad" */ '@gumtree/promote-ad');
        case pageNames.MANAGE_MY_ADS:
            return import(/* webpackChunkName: "manage-my-ads" */ '@gumtree/manage-my-ads');
        case pageNames.DEALER_AUCTION:
            return import(/* webpackChunkName: "dealer-auction" */ '@gumtree/dealer-auction');
        case pageNames.RESET_PASSWORD:
            return import(/* webpackChunkName: "reset-password" */ '@gumtree/reset-password');
        case pageNames.MANAGE_ACCOUNT:
            return import(/* webpackChunkName: "manage-account" */ '@gumtree/manage-account');
        case pageNames.SERVER_500:
            return import(/* webpackChunkName: "error-500" */ '@gumtree/error-500');
        default:
            throw new Error(`Cannot load bundle for page type: ${testNever(pageType) && pageType}`);
    }
};

/**
 * Typescript does not infer return type of above properly (missing summands),
 * so we define it manually.
 */
type AppAndReducersType =
    | typeof import(/* webpackChunkName: "home" */ '@gumtree/home')
    | typeof import(/* webpackChunkName: "syi" */ '@gumtree/syi')
    | typeof import(/* webpackChunkName: "vip" */ '@gumtree/vip')
    | typeof import(/* webpackChunkName: "sell-my-car" */ '@gumtree/sell-my-car')
    | typeof import(/* webpackChunkName: "user-profile" */ '@gumtree/user-profile')
    | typeof import(/* webpackChunkName: "recruiter-profile" */ '@gumtree/recruiter-profile')
    | typeof import(/* webpackChunkName: "job-pricing" */ '@gumtree/job-pricing')
    | typeof import(/* webpackChunkName: "error-404" */ '@gumtree/error-404')
    | typeof import(/* webpackChunkName: "price-guidance" */ '@gumtree/price-guidance')
    | typeof import(/* webpackChunkName: "message-centre" */ '@gumtree/message-centre')
    | typeof import(/* webpackChunkName: "reply-confirmation" */ '@gumtree/reply-confirmation')
    | typeof import(/* webpackChunkName: "static-pages" */ '@gumtree/static-pages')
    | typeof import(/* webpackChunkName: "srp" */ '@gumtree/srp')
    | typeof import(/* webpackChunkName: "sellerads" */ '@gumtree/sellerads')
    | typeof import(/* webpackChunkName: "bump-up" */ '@gumtree/bump-up')
    | typeof import(/* webpackChunkName: "profile" */ '@gumtree/profile')
    | typeof import(/* webpackChunkName: "cars" */ '@gumtree/cars')
    | typeof import(/* webpackChunkName: "register" */ '@gumtree/register')
    | typeof import(/* webpackChunkName: "login" */ '@gumtree/login')
    | typeof import(/* webpackChunkName: "promote-ad" */ '@gumtree/promote-ad')
    | typeof import(/* webpackChunkName: "manage-my-ads" */ '@gumtree/manage-my-ads')
    | typeof import(/* webpackChunkName: "dealer-auction" */ '@gumtree/dealer-auction')
    | typeof import(/* webpackChunkName: "reset-password" */ '@gumtree/reset-password')
    | typeof import(/* webpackChunkName: "manage-account" */ '@gumtree/manage-account')
    | typeof import(/* webpackChunkName: "error-500" */ '@gumtree/error-500');

export type AppsType = Extract<AppAndReducersType, { App: any }>['App'];
export type ReducersType = Extract<AppAndReducersType, { reducers: any }>['reducers'];

export type CombinedReducerType = Reducer<
    CombinedState<StateFromReducersMapObject<ReducersType>>,
    ActionFromReducersMapObject<ReducersType>
>;

export type PagesState = ReturnType<CombinedReducerType>;
